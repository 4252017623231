/* eslint-disable */

import * as d3 from 'd3';
import { isNumber } from 'lodash';
import React, { PureComponent } from 'react';

import {
  DisplayValueAlignmentFactors,
  FieldDisplay,
  getDisplayValueAlignmentFactors,
  getFieldDisplayValues,
  PanelProps,
  FieldConfig,
  DisplayProcessor,
  DisplayValue,
  VizOrientation,
} from '@grafana/data';
import { BarGaugeSizing } from '@grafana/schema';
import { BarGauge, DataLinksContextMenu, VizRepeater, VizRepeaterRenderValueProps } from '@grafana/ui';
import { DataLinksContextMenuApi } from '@grafana/ui/src/components/DataLinks/DataLinksContextMenu';
import { config } from 'app/core/config';
//import { BarGaugeOptions } from './types';
//import { config } from 'app/core/config';

import { Options, defaultOptions } from './panelcfg.gen';

export class BarGaugePanel extends PureComponent<BarGaugePanelProps> {
  renderComponent = (
    valueProps: VizRepeaterRenderValueProps<FieldDisplay, DisplayValueAlignmentFactors>,
    menuProps: DataLinksContextMenuApi
  ): JSX.Element => {
    const { options, fieldConfig } = this.props;
    const { value, alignmentFactors, orientation, width, height, count } = valueProps;
    const { field, display, view, colIndex } = value;
    const { openMenu, targetClassName } = menuProps;

    let processor: DisplayProcessor | undefined = undefined;
    if (view && isNumber(colIndex)) {
      processor = view.getFieldDisplayProcessor(colIndex);
    }

    const horizontal = orientation === VizOrientation.Horizontal;
    // //TODO: TO Be optimized
    // let scale: any = d3.scaleLinear();

    // //TODO: size of the text should be calculated not hardcoded!
    // const titleHeight = 22;
    // const marginBottom = 22;
    // if (horizontal) {
    //   scale = scale.domain([field.min || 0, field.max || 1000]);
    //   scale = scale.range([0, width - 132.84]);
    // } else {
    //   scale = scale.domain([field.max || 100, field.min || 0]);
    //   scale = scale.range([titleHeight, height - marginBottom]);
    // }
    // // scale.range([horizontal ? 0 : 16, horizontal ? width - 132.84 : height - 27]);
    // const axis = horizontal ? d3.axisBottom(scale) : d3.axisLeft(scale);

    // const padding = 30;

    // if (horizontal) {
    //   return (
    //     <div
    //       className={targetClassName}
    //       style={{
    //         display: 'flex',
    //         flexDirection: horizontal ? 'column' : 'row',
    //         background:
    //           'linear-gradient(rgba(156, 154, 154, 1) 0%, rgba(235, 235, 235, 1) 64%, rgba(235, 235, 235, 1) 84%)',
    //         borderRadius: 10,
    //         border: '3px solid black',
    //       }}
    //     >
    //       <BarGauge
    //         value={clearNameForSingleSeries(count, fieldConfig.defaults, display)}
    //         width={horizontal ? width : width * (2 / 4)}
    //         height={horizontal ? height : height * (2 / 4)}
    //         orientation={orientation}
    //         field={field}
    //         text={options.text}
    //         display={processor}
    //         theme={config.theme2}
    //         itemSpacing={this.getItemSpacing()}
    //         displayMode={options.displayMode}
    //         onClick={openMenu}
    //         alignmentFactors={count > 1 ? alignmentFactors : undefined}
    //         showUnfilled={options.showUnfilled}
    //       />
    //     </div>
    //   );
    // } else {
      return (
        <div
          className={targetClassName}
          style={{
            display: 'flex',
            flexDirection: horizontal ? 'column' : 'row',
            alignItems: 'flex-end',
            background:
              'linear-gradient(rgba(156, 154, 154, 1) 0%, rgba(235, 235, 235, 1) 64%, rgba(235, 235, 235, 1) 84%)',
            borderRadius: 10,
            border: '3px solid black',
            width: width + 'px',
          }}
        >

          <BarGauge
            className={targetClassName}
            value={clearNameForSingleSeries(count, fieldConfig.defaults, display)}
            width={width}
            height={height}
            orientation={orientation}
            field={field}
            text={options.text}
            display={processor}
            theme={config.theme2}
            itemSpacing={this.getItemSpacing()}
            displayMode={options.displayMode}
            onClick={openMenu}
            alignmentFactors={count > 1 ? alignmentFactors : undefined}
            showUnfilled={options.showUnfilled}
            valueDisplayMode={options.valueMode}
          />
        </div>
      );
    // }
  };

  renderValue = (valueProps: VizRepeaterRenderValueProps<FieldDisplay, DisplayValueAlignmentFactors>): JSX.Element => {
    const { value, orientation } = valueProps;
    const { hasLinks, getLinks } = value;

    if (hasLinks && getLinks) {
      return (
        <div style={{ width: '100%', display: orientation === VizOrientation.Vertical ? 'flex' : 'initial' }}>
          <DataLinksContextMenu style={{ height: '100%' }} links={getLinks}>
            {(api) => this.renderComponent(valueProps, api)}
          </DataLinksContextMenu>
        </div>
      );
    }

    return this.renderComponent(valueProps, {});
  };

  getValues = (): FieldDisplay[] => {
    const { data, options, replaceVariables, fieldConfig, timeZone } = this.props;

    return getFieldDisplayValues({
      fieldConfig,
      reduceOptions: options.reduceOptions,
      replaceVariables,
      theme: config.theme2,
      data: data.series,
      timeZone,
    });
  };

  getItemSpacing(): number {
    if (this.props.options.displayMode === 'lcd') {
      return 2;
    }

    return 10;
  }

  getOrientation(): VizOrientation {
    const { options, width, height } = this.props;
    const { orientation } = options;

    if (orientation === VizOrientation.Auto) {
      if (width > height) {
        return VizOrientation.Vertical;
      } else {
        return VizOrientation.Horizontal;
      }
    }

    return orientation;
  }

  calcBarSize() {
    const { options } = this.props;

    const orientation = this.getOrientation();
    const isManualSizing = options.sizing === BarGaugeSizing.Manual;
    const isVertical = orientation === VizOrientation.Vertical;
    const isHorizontal = orientation === VizOrientation.Horizontal;
    const minVizWidth = isManualSizing && isVertical ? options.minVizWidth : defaultOptions.minVizWidth;
    const minVizHeight = isManualSizing && isHorizontal ? options.minVizHeight : defaultOptions.minVizHeight;
    const maxVizHeight = isManualSizing && isHorizontal ? options.maxVizHeight : defaultOptions.maxVizHeight;

    return { minVizWidth, minVizHeight, maxVizHeight };
  }

  render() {
    const { height, width, options, data, renderCounter } = this.props;

    const { minVizWidth, minVizHeight, maxVizHeight } = this.calcBarSize();

    return (
      <VizRepeater
        source={data}
        getAlignmentFactors={getDisplayValueAlignmentFactors}
        getValues={this.getValues}
        renderValue={this.renderValue}
        renderCounter={renderCounter}
        width={width}
        height={height}
        maxVizHeight={maxVizHeight}
        minVizWidth={minVizWidth}
        minVizHeight={minVizHeight}
        itemSpacing={this.getItemSpacing()}
        orientation={options.orientation}
      />
    );
  }
}
export type BarGaugePanelProps = PanelProps<Options>;

export function clearNameForSingleSeries(count: number, field: FieldConfig, display: DisplayValue): DisplayValue {
  if (count === 1 && field.displayName) {
    return {
      ...display,
      title: undefined,
    };
  }

  return display;
}
